.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .popup {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: relative;
    max-width: 700px;
    width: 100%;
    margin: 10px;
    top:50px;
    z-index: 9999;
    @media #{$md-layout} {
      margin-top:100px;
    margin-bottom: 130px;
    max-width: 500px;
    overflow:auto;
  }
  @media #{$sm-layout} {
    margin-top:100px;
    margin-bottom: 130px;
    overflow:auto;
    
  }
   @media #{$large-mobile} {
    
    margin-top:100px;
    margin-bottom: 130px;
    overflow:auto;
   }
  }

  .popup-inner {
    display: flex;
    flex-direction: column;
    z-index: 9999;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #999;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .form-control,
  input {
    border: var(--border-lighter);
    border-radius: 16px;
    background-color: transparent;
    color: var(--color-text-dark);
    padding: 15px 20px;
    width: 100%;
    z-index: 9999;
  
    &:focus {
      outline: none;
      box-shadow: 0 20px 48px -14px rgba(153, 161, 170, 0.25);
      border-color: transparent;
    }
  
    &::placeholder {
      color: var(--color-gray-2);
      opacity: 1;
    }
  
    &:-ms-input-placeholder {
      color: var(--color-gray-2);
    }
  
    &::-ms-input-placeholder {
      color: var(--color-gray-2);
    }
  
    &.input-email {
      height: 60px;
    }
  }
  
  .form-group {
    margin-bottom: 16px;
  
    label {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      color: #444;
    }
  
    input {
      border: none;
      background-color: transparent;
    }
  }
  
  .input-group {
    background-color: #fff;
    border-radius: 20px;
    height: 72px;
    padding: 10px 10px 10px 55px;
    box-shadow: 0 24px 48px -15px rgba(153, 161, 170, 0.25);
  
    @media #{$small-mobile} {
      display: block;
      box-shadow: none;
      height: auto;
      padding: 0;
    }
  
    .mail-icon {
      position: absolute ;
      left: 30px;
      top: 22px;
      z-index: 1;
  
      @media #{$small-mobile} {
        left: 15px;
        top: 16px;
      }
    }
  
    input {
      padding: 5px 15px;
      height: auto;
      border: none;
      background-color: transparent;
  
      &:focus {
        box-shadow: none;
      }
  
      @media #{$small-mobile} {
        height: 60px;
        width: 100% !important;
        border-radius: 20px !important;
        padding: 5px 15px 5px 50px;
        border: 1px solid var(--color-light);
      }
  
      &::placeholder {
        color: var(--color-gray-2);
        opacity: 1;
      }
  
      &:-ms-input-placeholder {
        color: var(--color-gray-2);
      }
  
      &::-ms-input-placeholder {
        color: var(--color-gray-2);
      }
    }
  
    .subscribe-btn {
      border: none;
      background-color: #207fff;
      color: #fff;
      border-radius: 16px !important;
      margin-left: 0 !important;
      padding: 10px 28px;
      transition: var(--transition);
  
      &:hover {
        background-color: #0062cc;
      }
  
      @media #{$small-mobile} {
        margin-top: 15px;
        padding: 14px 30px;
      }
    }
  }
  
  .error-msg {
    color: #ff0000;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .success-msg {
    color: #15c54b;
    margin-bottom: 0;
    font-size: 14px;
  }